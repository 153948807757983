'use strict';

angular.module('enervexSalesappApp').controller('AccountEditCtrl', function(Auth, $scope, $stateParams, User, Account, AccountLocation, AccountRequest, Activity, State, Todolist, Job, Todo, Discussion, AccountActivity, Flash, _, ResidentialCompanyType) {
	$scope.accountId = $stateParams.accountId;
	$scope.isAdmin = Auth.isAdmin;
	$scope.isAccountOwner = Auth.isAccountOwner;
	$scope.accountStatuses = ['pending', 'active', 'archived'];
	$scope.accountTypes = ['commercial', 'residential'];
	$scope.ctx = {
		formChanged: false
	}
	if($scope.accountId != 'new'){
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
			fetchDeps()
		});
		AccountLocation.query({
			accountId: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.accountLocations = res
		})
	} else {
		fetchDeps()
	}
	function fetchDeps() {
		ResidentialCompanyType.query().$promise.then(function(result){
			$scope.residentialCompanyTypes = _.map(result, function(residentialCompanyType){
				var existing = _.find($scope.account.residentialCompanyTypes, function(o){
					return o._id == residentialCompanyType._id
				})
				if (existing){
					residentialCompanyType.selected = true
				}
				return residentialCompanyType
			})
		})
	}
	$scope.saveAccount = function(form) {
		$scope.submitted = true;
		$scope.success = false;
		var payload = _.clone($scope.account)
		payload.residentialCompanyTypes = _.compact(_.map($scope.residentialCompanyTypes, function(o){
			return o.selected && o._id
		}))
		Flash.clear()
		Account.update({
			id: $stateParams.accountId
		}, payload).$promise.then(function(res){
			
			$scope.success = true;
			var message = '<strong>Success!</strong> You successfully saved this account.';
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err) {
			
			$scope.errors = {};
			var messages = []
			_.each(err.data.errors, function(error, field) {
				// form[field].$setValidity('mongoose', false);
				$scope.errors[field] = error.message;
				messages.push(error.message)
			});
			Flash.create('danger',  '<strong>Error!</strong> Could not save account. '+ messages.join(". "), 0, {}, true);
		});
	}
});
